<template>
  <svg :id="'chart' + instanceId" class="w-full h-full text-green"></svg>
</template>

<script>
import { watchEffect, getCurrentInstance, onMounted } from "vue";
import { isMobile } from "@/global/services/mixins";
import { period } from "@/timeregistration/services/period";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import * as d3 from "d3";
export default {
  name: "Chart",
  props: {
    xData: Array,
    yData: Array,
    zData: Array,
    mobile: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    function getNewestContract(contracts) {
      const contract = contracts.reduce(function(prev, current) {
        return (prev.month > current.month && prev.year >= current.year) ||
          prev.year > current.year
          ? prev
          : current;
      });
      return contract;
    }

    const router = useRouter();
    const { t } = useI18n({
      messages: {
        en: {
          label1: "Hours worked this period",
          label2: "Hours left this period",
          label3: "Overtime from previous periods",
          label4: "Missing time from previous periods",
          h: "h",
          worked: "Worked",
          left: "Left",
          overtime: "Overtime",
          lastPeriod: "Previous periods"
        },
        dk: {
          label1: "Timer arbejdet i perioden",
          label2: "Timer til overs i perioden",
          label3: "Overarbejde fra forrige perioder",
          label4: "Manglende timer fra forrige perioder",
          h: "t",
          worked: "Arbejdet",
          left: "Til overs",
          overtime: "Overarbejde",
          lastPeriod: "Forrige perioder"
        }
      }
    });
    const instanceId = getCurrentInstance().uid;

    onMounted(() => {
      let data = [];
      const xValue = d => d.x;
      const yValue = d => d.y;
      const zValue = d => d.z;

      const chartContainer = d3.select("#chart" + instanceId);

      const margin = isMobile()
        ? { top: 5, right: 10, bottom: 5, left: 85 }
        : { top: 5, right: 10, bottom: 60, left: 85 };
      const width = +chartContainer.node().getBoundingClientRect().width;
      const height = +chartContainer.node().getBoundingClientRect().height;
      const innerWidth = width - margin.right - margin.left;
      const innerHeight = height - margin.top - margin.bottom;

      const xScale = isNaN(props.xData[0]) ? d3.scaleBand() : d3.scaleLinear();
      const yScale = isNaN(props.yData[0]) ? d3.scaleBand() : d3.scaleLinear();
      const xAxisCall = d3.axisBottom();
      const yAxisCall = d3.axisLeft();

      const initAxis = dataContainer => {
        dataContainer
          .append("g")
          .attr("class", "y axis")
          .call(yAxisCall);
      };

      const setScale = (iW, iH) => {
        // Array of newest contract hours for each employee
        const employeesContractHours = props.yData.map(e =>
          getNewestContract(e.data().hours)
        );

        const maxGoalHours =
          (Math.max(...employeesContractHours.map(c => c.value)) * 52) /12;

        function findMaxHours() {
          const maxWorked = d3.max(
            data.map(d => {
              return xValue(d);
            })
          );
          const maxThisMonth =
            maxWorked > maxGoalHours ? maxWorked : maxGoalHours;
          const overtimeMax = d3.max(
            data.map(d => {
              return maxThisMonth + zValue(d);
            })
          );
          const missingMax = d3.max(
            data.map(d => {
              return maxThisMonth - zValue(d);
            })
          );
          console.log(
            "max: ",
            overtimeMax >= missingMax ? overtimeMax : missingMax
          );
          return overtimeMax >= missingMax ? overtimeMax : missingMax;
        }

        xScale
          .domain([0, findMaxHours()])
          .range([0, iW])
          .nice();

        yScale
          .domain(data.map(yValue))
          .range([0, iH])
          .paddingInner(0.3)
          .paddingOuter(0.3);

        xAxisCall.scale(xScale);
        yAxisCall.scale(yScale);
      };

      const updateAxis = dataContainer => {
        const t = d3.transition().duration(500);

        dataContainer
          .select(".x")
          .transition(t)
          .call(xAxisCall);

        dataContainer
          .select(".y")
          .transition(t)
          .call(yAxisCall)
          .selectAll(".domain, .tick line")
          .remove();
      };

      const render = (dataContainer, yScale, xScale, iW, iH) => {
        data = [];
        /* console.log("Chart data:", props.xData, props.yData, props.zData); */
        for (let i = 0; i < props.xData.length; i++) {
          const name = props.yData.map(
            e => e.data().firstName.split(" ")[0] + " " + e.data().lastName[0]
          );
          data.push({
            x: props.xData[i],
            y: name[i],
            z: props.zData[i]
          });
        }

        setScale(iW, iH);
        updateAxis(dataContainer);

        if (!isMobile()) {
          chartContainer.select(".legend").remove();
          const legendContainer = chartContainer
            .append("g")
            .attr("class", "legend")
            .attr("transform", `translate(0, ${height - 55})`)
            .attr("fill", "#f5ae00");

          legendContainer
            .append("rect")
            .attr("transform", `translate(0, 0)`)
            .attr("height", 20)
            .attr("width", "150")
            .attr("fill", "#72b01d");

          legendContainer
            .append("text")
            .attr("class", "legend-text")
            .attr("transform", `translate(0, 30)`)
            .attr("fill", "#f3eff5")
            .attr("alignment-baseline", "middle")
            .text(t("label1"));

          legendContainer
            .append("rect")
            .attr("transform", `translate(150, 0)`)
            .attr("height", 20)
            .attr("width", "150")
            .attr("fill", "#009090");

          legendContainer
            .append("text")
            .attr("transform", `translate(150, 30)`)
            .attr("fill", "#f3eff5")
            .attr("alignment-baseline", "middle")
            .text(t("label2"));

          legendContainer
            .append("rect")
            .attr("transform", `translate(310, 0)`)
            .attr("height", 20)
            .attr("width", "50")
            .attr("fill", "#F87171");

          legendContainer
            .append("text")
            .attr("transform", `translate(366, 10)`)
            .attr("fill", "#f3eff5")
            .attr("alignment-baseline", "middle")
            .text(t("label3"));

          legendContainer
            .append("rect")
            .attr("transform", `translate(310, 26)`)
            .attr("height", 20)
            .attr("width", "50")
            .attr("fill", "#009090");

          legendContainer
            .append("text")
            .attr("transform", `translate(366, 36)`)
            .attr("fill", "#f3eff5")
            .attr("alignment-baseline", "middle")
            .text(t("label4"));
        }

        const t1 = d3.transition().duration(500);

        dataContainer.selectAll(".bar").remove();
        const bars = dataContainer.selectAll(".bar").data(data);

        const tooltip = d3
          .select("body")
          .append("div")
          .attr("class", "toolTip");

        const barsEnter = bars
          .enter()
          .append("g")
          .attr("class", "bar")
          .attr("width", iW);

        barsEnter
          .merge(bars)
          .attr("id", (d, i) => "barG" + i)
          .attr("transform", (d, i) => `translate(0, ${yScale(yValue(d))})`);

        bars.exit().remove();

        data.forEach((e, i) => {
          console.log("Chart loop employee");

          const contract = getNewestContract(props.yData[i].data().hours);
          const goalHours =
            contract.value === 0 ? 0 : (contract.value * 52 ) /12;

          //Vælg den ansattes container bar
          const nBar = d3.select("#barG" + i);

          nBar.attr("opacity", 0.6).style("cursor", "pointer");

          nBar.on("click", function() {
            router.push({
              name: "Registrations",
              query: { employeeId: props.yData[i].id }
            });
            tooltip.style("display", "none");
          });

          let stringWorkLeft = "";

          if (xValue(e) - goalHours < 0 && contract.value > 0) {
            stringWorkLeft = `${t(
              "left"
            )}: <span class='text-yellow'> ${Math.round(
              xValue(e) - goalHours
            )} ${t("h")}</span> <br/>`;
          } else if (xValue(e) - goalHours >= 0 && contract.value > 0) {
            stringWorkLeft = `${t(
              "overtime"
            )}: <span class='text-yellow'> ${Math.round(
              xValue(e) - goalHours
            )} ${t("h")}</span> <br/>`;
          }

          let stringWorkLastPeriod = "";

          if (zValue(e) >= 0 && contract.value > 0) {
            stringWorkLastPeriod = `${t(
              "lastPeriod"
            )}: <span class='text-yellow'> ${Math.round(zValue(e))} ${t(
              "h"
            )}</span> <br/>`;
          } else if (zValue(e) < 0 && contract.value > 0) {
            stringWorkLastPeriod = `${t(
              "lastPeriod"
            )}: <span class='text-yellow'> ${Math.round(zValue(e))} ${t(
              "h"
            )}</span> <br/>`;
          }

          nBar
            .on("mouseenter", function(event) {
              nBar.attr("opacity", 1);
              tooltip
                .style("left", event.pageX - 110 + "px")
                .style("top", event.pageY - 120 + "px")
                .style("display", "inline-block")
                .html(
                  "<span class=' text-yellow'>" +
                    yValue(e) +
                    " </span> <br/>" +
                    t("worked") +
                    ": " +
                    "<span class=' text-yellow'>" +
                    Math.round(xValue(e)) +
                    " " +
                    t("h") +
                    "</span> <br/>" +
                    stringWorkLeft +
                    stringWorkLastPeriod
                );
            })
            .on("mouseout", function(d) {
              nBar.attr("opacity", 0.6);
              tooltip.style("display", "none");
            });

          // Lav bar til det der arbejdet i måneden
          nBar
            .append("rect")
            .attr("height", yScale.bandwidth())
            .attr("fill", "#72b01d")
            .transition(t1)
            .attr("width", xValue(e) === 0 ? 0 : xScale(xValue(e)))
            .delay(i * 150);

          // Manglende arbejde
          if (xValue(e) - goalHours < 0) {
            nBar
              .append("rect")
              .attr("transform", `translate(${xScale(xValue(e))}, 0)`)
              .attr("height", yScale.bandwidth())
              .attr("fill", "#009090")
              .transition(t1)
              .attr("width", xScale(-(xValue(e) - goalHours)))
              .delay(i * 150);
          }

          // Overarbejde
          if (zValue(e) > 0) {
            const workLeft =
              xValue(e) - goalHours >= 0 ? 0 : -(xValue(e) - goalHours);
            nBar
              .append("rect")
              .attr(
                "transform",
                `translate(${xScale(xValue(e) + workLeft + 5)}, 0)`
              )
              .attr("height", yScale.bandwidth())
              .attr("fill", "#F87171")
              .transition(t1)
              .attr("width", xScale(zValue(e)))
              .delay(i * 150);
          }

          // Manglende arbejde
          if (zValue(e) < 0) {
            const workLeft =
              xValue(e) - goalHours >= 0 ? 0 : -(xValue(e) - goalHours);
            nBar
              .append("rect")
              .attr(
                "transform",
                `translate(${xScale(xValue(e) + workLeft + 5)}, 0)`
              )
              .attr("height", yScale.bandwidth())
              .attr("fill", "#009090")
              .transition(t1)
              .attr("width", xScale(-zValue(e)))
              .delay(i * 150);
          }

          // Barer som viser hvor man burde være

          /* nBar
            .append("rect")
            .attr(
              "transform",
              `translate(${xScale(
                ((goalHours - e.z) / daysInPeriod) * (daysInPeriod - daysLeft)
              )}, 0)`
            )
            .attr("height", yScale.bandwidth())
            .attr("width", xScale(1))
            .attr("fill", "#F5AE00");
          nBar
            .append("rect")
            .attr(
              "transform",
              `translate(${xScale(
                (goalHours / daysInPeriod) * (daysInPeriod - daysLeft)
              )}, 0)`
            )
            .attr("height", yScale.bandwidth())
            .attr("width", xScale(1))
            .attr("fill", "#ED1C24"); */
        });
      };

      const dataG = chartContainer
        .append("g")
        .attr("transform", `translate(${margin.left}, ${margin.top})`);

      setScale(innerWidth, innerHeight);
      initAxis(dataG, innerHeight);

      watchEffect(() => {
        if (
          props.xData !== undefined &&
          props.yData !== undefined &&
          props.zData !== undefined
        )
          render(dataG, yScale, xScale, innerWidth, innerHeight);
      });
    });

    return { instanceId };
  }
};
</script>

<style>
text {
  font-family: "Bicyclette";
  font-size: 0.8rem;
}

.axis-label {
  fill: #009090;
  font-size: 1rem;
  font-weight: 600;
}

.title {
  fill: #f3eff5;
  font-size: 1rem;
  font-weight: 600;
}

.tick {
  color: #f3eff5;
  font-weight: 600;
}

.toolTip {
  position: absolute;
  display: none;
  height: 110px;
  width: 220px;
  background: none repeat scroll 0 0 rgba(1, 63, 75, 0.8);
  color: #fff;
  border: 2px solid rgba(6, 90, 90, 0.8);
  text-align: center;
  border-radius: 6px;
  font-size: 0.875rem;
}

/* Creates a small triangle extender for the tooltip */
.toolTip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  line-height: 1;
  color: rgba(1, 63, 75, 0.8);
  content: "\25BC";
  position: absolute;
  bottom: -10px;
  left: 50%;
  text-align: center;
}
</style>

<template>
  <slot v-if="error" name="error" v-bind:err="err" v-bind:info="info"
    ><div
      class="w-full h-full flex flex-col items-center justify-center relative
        mt-3"
    >
      <span class=" text-green text-6xl mb-6">
        <i class="fas fa-exclamation-circle"></i>
      </span>
      <p class=" text-yellow mb-3 text-2xl">Åh nej, noget gik galt!</p>
      <p class=" text-green text-center text-lg">
        Vi beklager ulejligheden, fejlen er registreret, og vi håber at have
        løst problemet hurtigst muligt.
      </p>
    </div></slot
  >
  <slot v-else></slot>
</template>

<script>
import { onErrorCaptured, ref } from "vue";
import { errorLogging } from "@/global/services/errorLogging";
export default {
  name: "errorBoundary",
  setup() {
    const state = {
      error: ref(false),
      vm: ref(null),
      info: ref(null)
    };
    onErrorCaptured((err, vm, info) => {
      state.error.value = err;
      state.info.value = info;
      errorLogging.setError(
        err.message,
        vm.$route.fullPath,
        vm.$.type.name,
        info
      );
      console.log(err);
      return false;
    });
    return { ...state };
  }
};
</script>

<style lang="scss"></style>

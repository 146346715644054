<template>
  <PageErrorBoundary>
    <Header title="Dashboard" icon="fa-chart-bar" />

    <Content
      class="overflow-y-none overflow-x-none items-center justify-center flex-col"
    >
      <div
        v-if="chartTotalHours.loaded && (isOwner() || isLeader())"
        class="w-11/12 my-3 h-64 md:h-80 md:w-3/4 md:flex md:flex-col md:items-center md:justify-center relative bg-green-darkest rounded-md mx-4 px-1 md:px-4 shadow-xl border-2 border-green-dark"
      >
        <ErrorBoundary>
          <ChartTotalHours
            v-if="chartTotalHours.loaded"
            :yData="chartTotalHours.users"
            :xData="chartTotalHours.totals"
            :zData="chartTotalHours.restHours"
          />
        </ErrorBoundary>
      </div>
      <!-- <div
        v-if="chartAverageData.loaded && false"
        class="w-11/12 my-3 md:h-80 md:w-3/4 md:flex md:flex-col md:items-center md:justify-center relative bg-green-darkest rounded-md mx-4 px-1 md:px-4 shadow-xl border-2 border-green-dark"
      >
        <ChartC
          v-if="chartAverageData.loaded"
          :yData="chartAverageData.users"
          :xData="chartAverageData.averages"
        />
      </div> -->
      <div
        v-if="
          (ownerRegistersTime() || isEmployee() || isLeader()) &&
            times.length !== 0
        "
        class="w-11/12 my-3 h-56 md:h-52 md:w-3/4 md:flex md:flex-col md:items-center md:justify-center relative bg-green-darkest rounded-md mx-4 px-1 md:px-4 shadow-xl border-2 border-green-dark"
      >
        <ChartC v-if="chartLoaded === true" :xData="dates" :yData="times" />
      </div>
      <div
        v-if="ownerRegistersTime() || isEmployee() || isLeader()"
        class="w-11/12 h-20 my-3 md:w-3/4 flex items-center justify-center bg-green-darkest rounded-md mx-4 px-4 shadow-xl border-2 border-green-dark"
      >
        <span class="flex flex-col w-1/3 justify-center items-center">
          <p class="text-white text-xl mt-3">{{ t("workDone") }}</p>
          <p class="text-yellow flex tracking-widest text-2xl">
            {{ Math.floor(totalHours) }}
            <span class="ml-2 text-green mr-3">{{ t("h") }}</span>
            {{ Math.round((totalHours % 1) * 60) }}
            <span class="ml-2 text-green">m</span>
          </p>
        </span>
        <template v-if="contract">
          <span
            v-if="contract.value > 0"
            class="flex flex-col w-1/3 justify-center items-center"
          >
            <p class="text-white md:text-xl mt-3">
              {{ totalHours - goalHours > 0 ? t("overtime") : t("workLeft") }}
            </p>
            <p
              class="flex md:tracking-widest text-lg md:text-2xl"
              :class="[
                totalHours - goalHours > 0 ? 'text-red-500' : 'text-green-light'
              ]"
            >
              {{
                totalHours - goalHours > 0
                  ? Math.floor(totalHours - goalHours)
                  : -Math.ceil(totalHours - goalHours)
              }}
              <span class="ml-1 text-green mr-3">{{ t("h") }}</span>
              {{
                totalHours - goalHours > 0
                  ? Math.round(((totalHours - goalHours) % 1) * 60)
                  : -Math.round(((totalHours - goalHours) % -1) * 60)
              }}
              <span class="ml-1 text-green">m</span>
            </p>
          </span>
        </template>
        <template v-if="userOvertime !== null && contract">
          <span
            v-if="contract.value > 0"
            class="flex flex-col w-1/3 justify-center items-center"
          >
            <p class="text-white text-xl mt-3">
              {{ t("previousPeriod") }}
            </p>
            <p
              v-if="userOvertime >= 0"
              class="text-red-500 flex tracking-widest text-2xl"
            >
              +
              {{ Math.floor(userOvertime) }}
              <span class="ml-2 text-green mr-3">{{ t("h") }}</span>
              {{ Math.round((userOvertime % 1) * 60) }}
              <span class="ml-2 text-green">m</span>
            </p>
            <p
              v-if="userOvertime < 0"
              class="text-green-light flex tracking-widest text-2xl"
            >
              -
              {{ -Math.ceil(userOvertime) }}
              <span class="ml-2 text-green mr-3">{{ t("h") }}</span>
              {{ -Math.round((userOvertime % -1) * 60) }}
              <span class="ml-2 text-green">m</span>
            </p>
          </span>
        </template>
      </div>
    </Content>
  </PageErrorBoundary>
</template>

<script>
import { ref, watchEffect, onMounted } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import Header from "@/components/Header";
import Content from "@/components/Content";
import ChartC from "@/components/Chart";
import ChartTotalHours from "@/components/ChartTotalHours";
import { authentication } from "@/global/services/authentication";
import { period } from "@/timeregistration/services/period";
import { units } from "@/global/services/units";
import { timeRegistration } from "@/timeregistration/services/timeRegistration";
import { farm } from "@/global/services/farm";
import { employee } from "@/global/services/employee";

import PageErrorBoundary from "@/global/components/pageErrorBoundary";
import ErrorBoundary from "@/global/components/errorBoundary";
import { useI18n } from "vue-i18n";

export default {
  name: "page-dashboard",
  components: {
    Header,
    Content,
    ChartC,
    ChartTotalHours,
    PageErrorBoundary,
    ErrorBoundary
  },
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          workDone: "Work done",
          workLeft: "Work left",
          overtime: "Overtime",
          previousPeriod: "Previous periods",
          h: "h"
        },
        dk: {
          workDone: "Udført arbejde",
          workLeft: "Arbejde til overs",
          overtime: "Overarbejde",
          previousPeriod: "Forrige perioder",
          h: "t"
        }
      }
    });
    const state = {
      employeeDoc: employee.get(true),
      userOvertime: ref(null),
      chart: ref(),
      chart2: ref(),
      goalHours: ref(0),
      contract: ref(null),
      totalHours: ref(0),
      totalMinutes: ref(0),
      dates: ref([]),
      times: ref([]),
      chartLoaded: ref(false),
      userTimeRegistrations: timeRegistration.getUserTimeRegistrationsList(
        true
      ),
      /* chartAverageData: ref({ users: [], averages: [], loaded: false }), */
      chartTotalHours: ref({
        users: [],
        totals: [],
        restHours: [],
        loaded: false
      })
    };

    /*     const as = new Worker("authentication-service-worker.js")
as.addEventListener('message', event => {
  // event is a MessageEvent object
  console.log(`The service worker sent me a message: ${event.data}`);
});
as.postMessage({ cmd: "initializeApp", message: "Hi web worker!" })
 */
    watchEffect(() => {
      if (units.getSelectedUnitEmployees().length !== 0) {
        if (authentication.isOwner() || authentication.isLeader()) {
          timeRegistration.updateTimeRegistrationsInInterval();
        }
      }
    });

    watchEffect(() => {
      if (process.env.NODE_ENV === "development")
        console.log("Getting latest monthReport");
      if (
        farm.getMonthReports(true).value.length !== 0 &&
        farm.getMonthReports(true).value.length ===
          farm.getMonthReportsLength() &&
        state.employeeDoc.value
      ) {
        const r = farm.getLatestMonthReport();
        if (r) {
          if (r.data()[state.employeeDoc.value.id] !== undefined) {
            state.userOvertime.value = farm.getLatestMonthReport().data()[
              state.employeeDoc.value.id
            ]["sumOvertime"];
          }
        }
      }
    });

    watchEffect(() => {
      if (process.env.NODE_ENV === "development")
        console.log("Updating user times");
      if (units.getSelectedUnit()) {
        if (units.getOwnerRegistersTime() || !authentication.isOwner()) {
          timeRegistration.updateUserTimes();
        }
      }
    });

    function diffTime(reg) {
      const dt1 = new Date(reg.data().start);
      const dt2 = new Date(reg.data().end);
      let diff = dt2.getTime() - dt1.getTime();
      reg.data().breakDuration !== undefined
        ? (diff = diff - reg.data().breakDuration)
        : null;
      const diffMin = Math.round((diff * 100) / 3600000) / 100;
      return diffMin;
    }

    // Code for week reports
    /*  watchEffect(() => {
      if (state.allTimes.value.length) {
        const mon = new Date();
        const sun = new Date();
        mon.setHours(0, 0, 0, 0);
        mon.setDate(mon.getDate() - 6);
        sun.setHours(23, 59, 59, 999);

        const times = state.allTimes.value.filter(t => !t.data().deleted);
        for (let i = 0; i < 20; i++) {
          if (i !== 0) {
            mon.setDate(mon.getDate() - 7);
            sun.setDate(sun.getDate() - 7);
          }
          const timesF = times.filter(
            t =>
              t.data().start > mon.getTime() && t.data().start < sun.getTime()
          );
          const reducer = (a, c) => a + diffTime(c);
          const res = timesF.reduce(reducer, 0);
          console.log(res);
        }
      }
    }); */

    onMounted(() => {
      watchEffect(() => {
        if (process.env.NODE_ENV === "development")
          console.log("Making chart for all employees");
        state.chartTotalHours.value.loaded = false;
        state.chartTotalHours.value.users = [];
        state.chartTotalHours.value.totals = [];
        state.chartTotalHours.value.restHours = [];
        if (
          (authentication.isOwner() || authentication.isLeader()) &&
          units.getSelectedUnitEmployees().length !== 0 &&
          timeRegistration.getTimeRegistrationsInIntervalList().length ===
            timeRegistration.getNumberOfTimeRegistrations()
        ) {
          const monthReport = farm.getLatestMonthReport();
          units
            .getSelectedUnitEmployees()
            .filter(e => e.data().deleted === null)
            .forEach(element => {
              if (units.getOwnerRegistersTime()) {
                let total = 0;
                const employee = element;
                state.chartTotalHours.value.users.push(employee);
                const userTimes = timeRegistration
                  .getTimeRegistrationsInIntervalList()
                  .filter(
                    t =>
                      t.data().uid === element.data().uid &&
                      t.data().end !== false &&
                      !t.data().deleted
                  );
                if (userTimes.length !== 0) {
                  userTimes.forEach(r => {
                    total += diffTime(r);
                  });
                  state.chartTotalHours.value.totals.push(total);
                } else {
                  state.chartTotalHours.value.totals.push(0);
                }

                if (monthReport) {
                  monthReport.data()[element.id]
                    ? state.chartTotalHours.value.restHours.push(
                        monthReport.data()[element.id].sumOvertime
                      )
                    : state.chartTotalHours.value.restHours.push(0);
                } else {
                  state.chartTotalHours.value.restHours.push(0);
                }
              } else if (element.data().uid !== farm.getOwnerUid()) {
                let total = 0;
                const employee = element;
                state.chartTotalHours.value.users.push(employee);
                const userTimes = timeRegistration
                  .getTimeRegistrationsInIntervalList()
                  .filter(
                    t =>
                      t.data().uid === element.data().uid &&
                      t.data().end !== false &&
                      !t.data().deleted
                  );
                if (userTimes.length !== 0) {
                  userTimes.forEach(r => {
                    total += diffTime(r);
                  });
                  state.chartTotalHours.value.totals.push(total);
                } else {
                  state.chartTotalHours.value.totals.push(0);
                }
                if (monthReport) {
                  monthReport.data()[element.id]
                    ? state.chartTotalHours.value.restHours.push(
                        monthReport.data()[element.id].sumOvertime
                      )
                    : state.chartTotalHours.value.restHours.push(0);
                } else {
                  state.chartTotalHours.value.restHours.push(0);
                }
              }
            });
          state.chartTotalHours.value.loaded = true;
        }
      });

      /* function median(values) {
        if (values.length === 0) return 0;

        values.sort(function(a, b) {
          return a - b;
        });
        const half = Math.floor(values.length / 2);

        if (values.length % 2) return values[half];

        return (values[half - 1] + values[half]) / 2.0;
      } */

      /* watchEffect(() => {
        if (
          units.getSelectedUnitEmployees().length !== 0 &&
          timeRegistration.getTimeRegistrationsInIntervalList().length ===
            timeRegistration.getNumberOfTimeRegistrations()
        ) {
          units.getSelectedUnitEmployees().forEach(element => {
            const userTimes = timeRegistration
              .getTimeRegistrationsInIntervalList()
              .filter(t => t.uid === element.uid && t.end !== false);
            const userDurations = userTimes.map(t => diffTime(t.end, t.start));
            const medianValue = median(userDurations);
            state.chartAverageData.value.users.push(
              element.firstName + " " + element.lastName
            );
            state.chartAverageData.value.averages.push(medianValue);
          });
          state.chartAverageData.value.loaded = true;
        }
      }); */

      const formatDateChart = time => {
        const date = new Date(parseInt(time));
        date.setHours(0, 0, 0, 1);
        return date.toISOString();
      };

      watchEffect(() => {
        if (process.env.NODE_ENV === "development")
          console.log("Getting contract");
        if (state.employeeDoc.value) {
          if (state.employeeDoc.value.data().hours) {
            const reducer = (pre, cur) => {
              if (cur.year > pre.year || pre === undefined) {
                return cur;
              } else if (cur.month > pre.month && cur.year === pre.year) {
                return cur;
              } else if (
                cur.date > pre.date &&
                cur.month === pre.month &&
                cur.year === pre.year
              ) {
                return cur;
              }
            };
            state.contract.value = state.employeeDoc.value
              .data()
              .hours.reduce(reducer);
            const currentDate = new Date();
            currentDate.setMonth(currentDate.getMonth() + 1);
            currentDate.setDate(0);
            state.goalHours.value = (state.contract.value.value * 52) / 12;
          }
        }
      });

      watchEffect(() => {
        if (process.env.NODE_ENV === "development")
          console.log("Making chart for user");
        state.chartLoaded.value = false;
        state.dates.value = [];
        state.times.value = [];
        state.totalHours.value = 0;
        state.totalMinutes.value = 0;
        if (
          state.userTimeRegistrations.value.length !== 0 &&
          timeRegistration.getNumberOfUserTimeRegistrations()
        ) {
          let totalTime = 0;

          const userTimesFiltered = timeRegistration
            .getUserTimeRegistrationsList()
            .filter(t => t.data().end !== false && !t.data().deleted);

          if (userTimesFiltered.length !== 0) {
            state.dates.value = [];
            state.times.value = [];
            state.dates.value = period.getPeriodDateList();
            state.dates.value.forEach(() => {
              state.times.value.push(0);
            });

            userTimesFiltered.forEach(e => {
              const idx = state.dates.value.indexOf(
                formatDateChart(e.data().start)
              );
              const diff = diffTime(e);
              state.times.value[idx] += diff;
              totalTime += diff;
            });
            state.totalHours.value = totalTime;
            state.chartLoaded.value = true;
          }
        }
      });
    });
    onBeforeRouteLeave(() => {
      if (state.chart.value !== undefined) {
        state.chart.value.clear();
      }
    });
    return {
      t,
      ...state,
      isOwner: authentication.isOwner,
      isLeader: authentication.isLeader,
      isEmployee: authentication.isEmployee,
      ownerRegistersTime: units.getOwnerRegistersTime
    };
  }
};
</script>
